import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useStaticQuery, graphql } from 'gatsby'
import Avatar from '@material-ui/core/Avatar';
import tripadvisorLogo from '../../assets/logo/tripadvisor.svg';





const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d4d4d4',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    reviewAvatar: {
        margin: 10,
        width: 124,
        height: 124,
    },
    tripadvisorLogo: {
        margin: 10,
        width: 50,
        height: 50,
        justifyContent: 'center',
    },

}));



const ReviewBox = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allPrismicReviewbox {
                edges {
                  node {
                    uid
                    data {
                      title {
            
                        text
                      }
                      bodytext {
            
                        text
                      }
                      photo{
                        url
                      }
                      tripadvisorlink {
                        text
                      }
                    }
                  }
                }
            }
          }
             
        `
    )



    const classes = useStyles();

    return (
        <React.Fragment>

            <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    {data.allPrismicReviewbox.edges.map(x => (
                        <Grid item key={1} xs={12} sm={6} md={4}>
                            <Card className={classes.card} >

                                <CardMedia style={{ display: 'flex', justifyContent: 'center' }}>
                                    <a href={x.node.data.tripadvisorlink.text} target="_blank" rel="noopener noreferrer">

                                        <img src={tripadvisorLogo} alt="Trip Advisor" className={classes.tripadvisorLogo} />
                                    </a>
                                </CardMedia>
                                <CardMedia style={{ display: 'flex', justifyContent: 'center' }}>

                                    <Avatar alt="Remy Sharp" src={x.node.data.photo.url} className={classes.reviewAvatar} />
                                </CardMedia>
                                <CardContent className={classes.cardContent} >
                                    <Typography gutterBottom variant="h5" component="h2" align="center">
                                        {x.node.data.title.text}
                                    </Typography>
                                </CardContent>
                                <CardContent className={classes.cardContent} >
                                    <Typography gutterBottom variant="body1" component="h2" align="center">
                                        {x.node.data.bodytext.text}
                                    </Typography>
                                </CardContent>

                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
}

export default ReviewBox