import React from "react"

import { RichText } from 'prismic-reactjs'
import { useStaticQuery, graphql } from "gatsby"
import Button from "@material-ui/core/Button"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Card from "@material-ui/core/Card"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import SEO from "../../components/seo"




const HomePageView = () => {
  const data = useStaticQuery(
    graphql`
      query {
        homepage: prismicHomepage {
          data {
            herophoto1 {
              url
            }
            herotitle1 {
              text
            }
            herotext1 {
              text
            }
            herophoto2 {
               url
            }
            herotitle2 {
              text
              
            }
            herotext2 {
              text
              html
            }
            seotitle {
              text
            }
            seodescription {
              text
            }
            seokeywords {
              text
            }
          }
        }
      }
    `
  )

  const useStyles = makeStyles(theme => ({
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(8),


      backgroundImage: `url(${data.homepage.data.herophoto1.url})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },

    heroButtons: {
      marginTop: theme.spacing(4),
    },
    card: {
      maxWidth: 1200,
      textAlign: "center",
      alignContent: "center",
    },
  }))
  const classes = useStyles()

  return (

    <React.Fragment>


      <SEO
        title={data.homepage.data.seotitle.text}
        description={data.homepage.data.seodescription.text}
        keywords={data.homepage.data.seokeywords.text}
      />

      {/* <TopSpacing /> */}
      <div>
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              {data.homepage.data.herotitle1.text}
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              {data.homepage.data.herotext1.text}
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">

                <Grid item>

                  <Button variant="contained" color="primary">
                    Tour 1
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary">
                    Tour 2
                  </Button>
                </Grid>

              </Grid>
            </div>
          </Container>
        </div>
        <div>
          <Container>
            <Card className={classes.card}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Contemplative Reptile"
                  height="300"
                  image={data.homepage.data.herophoto2.url}
                  title="Contemplative Reptile"
                />



                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {data.homepage.data.herotitle2.text}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {data.homepage.data.herotext2.text}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button variant="contained" color="primary">
                  learn more
                </Button>
              </CardActions>
            </Card>
          </Container>
        </div>
      </div>
      <p> <div dangerouslySetInnerHTML={{ __html: data.homepage.data.herotext2.html }} /></p>


    </React.Fragment>
  )
}

export default HomePageView
