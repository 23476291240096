import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useStaticQuery, graphql , Link} from 'gatsby'

import Img from "gatsby-image"







const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
   
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#d4d4d4',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },


}));


const BlogBox = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allPrismicBlogbox {
                edges {
                  node {
                    uid
                    data {
                      title {
            
                        text
                      }
                      photo{
                        localFile {
                            childImageSharp {
                              fluid(maxWidth: 500, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                              }
                            }
                          }
                      }
                    }
                  }
                }
            }
          }
             
        `
    )



    const classes = useStyles();

    return (
        <React.Fragment>

            <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={3}>
                    {data.allPrismicBlogbox.edges.map(x => (
                        <Grid item key={1} xs={12} sm={4} md={3}>
                            <Link to={`/blogs/${x.node.uid}/`} style={{ textDecoration: 'none' }}>
                            <Card className={classes.card} >
                                <CardMedia  >
                                    <Img
                                        fluid={x.node.data.photo.localFile.childImageSharp.fluid}
                                    />
                                </CardMedia>
                                <CardContent className={classes.cardContent} >
                                    <Typography gutterBottom variant="h6" component="h2" align="center">
                                        {x.node.data.title.text}
                                    </Typography>
                                </CardContent>

                            </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>

        </React.Fragment>
    );
}

export default BlogBox