import { Helmet } from "react-helmet"
import React from "react"
class Application extends React.Component {
    render() {
        return (
            <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no ,user-scalable=no" />


                </Helmet>
            </div>
        )
    }
}
export default Application