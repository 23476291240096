import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useStaticQuery, graphql, Link } from 'gatsby'


import Img from "gatsby-image"




const useStyles = makeStyles(theme => ({
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },

}));



const ActivityBox = () => {
    const data = useStaticQuery(
        graphql`
          query {
            allPrismicActivitybox {
              edges {
                node {
                  uid
                    data {
                    title {
                      text
                    }
                    description {
                      text
                    }
                    photo {
                      localFile {
                        childImageSharp {
                            fluid(maxWidth: 500, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_tracedSVG
                              }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
             
        `
    )



    const classes = useStyles();

    return (
        <React.Fragment>

            <Container className={classes.cardGrid} maxWidth="md">
                {/* End hero unit */}
                <Grid container spacing={4}>
                    <Grid item key={1} xs={12} sm={6} md={4}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.card}
                                image="https://alibabatrekcom.cdn.prismic.io/alibabatrekcom/911225a04edc068fb2b9c2af662237ea91643a4b_irantour.jpg"
                            >

                            </CardMedia>
                            <CardContent className={classes.cardContent}>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Damavand
                                    </Typography>
                            </CardContent>
                            <CardActions>
                                <Link to={`/mount-damavand/`}>
                                    <Button size="small" color="primary">
                                        Details
                                    </Button>
                                </Link>

                            </CardActions>
                        </Card>
                    </Grid>
                    {data.allPrismicActivitybox.edges.map(x => (
                        <Grid item key={1} xs={12} sm={6} md={4}>
                            <Link to={`/activities/${x.node.uid}/`} style={{ textDecoration: 'none' }}>

                                <Card className={classes.card}>
                                    <CardMedia>
                                        <Img
                                            fluid={x.node.data.photo.localFile.childImageSharp.fluid}
                                        />
                                    </CardMedia>
                                    <CardContent className={classes.cardContent}>
                                        <Typography gutterBottom variant="h5" component="h2">
                                            {x.node.data.title.text}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" color="primary">
                                            Details
                                    </Button>


                                    </CardActions>
                                </Card> </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default ActivityBox