import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import MainHeader from "../components/Header"
import MainPage from "../views/HomePage"
import ActivityBox from "../views/activityBox"
import ReviewBox from "../views/reviewBox"
import MainFooter from "../components/Footer"
import BlogBox from "../views/blogBox"
import MetaTagsIndex from '../components/meta'

export default function AlibabatrekIndex() {
  return (
    <React.Fragment>
      <CssBaseline />
      <MetaTagsIndex />

      <MainHeader className="major" />
      <main>
        <MainPage />
        <ActivityBox />
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Reviews
        </Typography>
        <ReviewBox />
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Blogs
        </Typography>
        <BlogBox />
      </main>
      <MainFooter />
    </React.Fragment>
  )
}
